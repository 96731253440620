import { Table, SortBy } from '@sixfold/table-component';
import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Popup, Icon } from 'semantic-ui-react';

import { useTimeZone } from '../../components/date_formatting/timezone_context';
import { getTableHeaderClassNames, emptyTableState } from '../../components/table';
import { Tags } from '../../components/tags';
import { formatDateWithTimeZone } from '../../lib/date';
import { notEmpty, TourFailureReasonType, VisibilityIssueType } from '../../lib/graphql';
import { shipmentViewUrl } from '../../lib/util/url';
import { Routes } from '../../routes';
import { TourWarning, VisibilityIssue, VisibilityService } from '../entities';
import { getStatusLabelColor } from '../utils';

// prettier-ignore
export interface Tour {
  tour_id: number;
  transport_id: string | null;
  shipper_transport_number: string | null;
  start_time: string | null;
  status: string | null;
  failureReason: {
    type: TourFailureReasonType;
  } | null;
  warnings: TourWarning[];
  mainVisibilityIssue: VisibilityIssue | null;
  visibilityServices: VisibilityService[];
  shipper: {
    company_id: string;
    company_name: string | null;
  } | null;
  carrier: {
    company_id: string;
    company_name: string | null;
  } | null;
  platform: {
    platform_id: string;
    platform_name: string;
  } | null;
  vehicles: {
    vehicle_id: string;
    license_plate_number: string | null;
  }[];
  stops:
    | ({
        sequence_number: number | null;
        actual_sequence_number: number | null;
        display_sequence_number: number;
        location: {
          name: string | null;
          address: {
            country: string | null;
          } | null;
        } | null;
      } | null)[]
    | null;
  tags: {
    name: string;
  }[];
}

export interface FormattedTour {
  tour_id: number;
  transport_id: string | null;
  from_to: string;
  status: string;
  failureReasonType: TourFailureReasonType | null;
  warnings: string | null;
  mainVisibilityIssue: VisibilityIssueType | null;
  visibility_services: VisibilityService[];
  shipper_transport_number: string;
  vehicles: { vehicle_id: string; license_plate_number: string | null }[];
  carrier_name: string;
  carrier_id: string | null;
  shipper_name: string;
  shipper_id: string | null;
  platform_name: string;
  platform_id: string | null;
  start_time: string;
  iso_start_time: string;
  tags: { name: string }[];
}

export interface TourListProps {
  data: { tours: Tour[] };
  sortBy?: SortBy;
  onSortChange?: (SortBy: SortBy) => void;
}

const fromTo = (tour: Tour) => {
  const stops = tour.stops?.filter(notEmpty) ?? [];

  if (stops.length === 0) {
    return '[no stops]';
  }

  const from = stops[0];
  const to = stops[stops.length - 1];

  return [
    from.location?.name ?? `Stop ${from.display_sequence_number}`,
    to.location?.name ?? `Stop ${to.display_sequence_number}`,
  ].join(' - ');
};
class TourTable extends Table<FormattedTour> {}

export const TourList: React.StatelessComponent<TourListProps> = (props) => {
  const {
    data: { tours },
    sortBy,
    onSortChange,
  } = props;

  const { timeZone } = useTimeZone();
  const formattedTours: FormattedTour[] = tours.map((tour) => ({
    tour_id: tour.tour_id,
    transport_id: tour.transport_id,
    platform_name: tour.platform?.platform_name ?? '-',
    platform_id: tour.platform?.platform_id ?? null,
    status: tour.status ?? '-',
    failureReasonType: tour.failureReason?.type ?? null,
    warnings: tour.warnings.length > 0 ? tour.warnings.map(({ type }) => type).join(', ') : null,
    mainVisibilityIssue: tour.mainVisibilityIssue?.type ?? null,
    visibility_services: tour.visibilityServices,
    vehicles: tour.vehicles,
    shipper_transport_number: tour.shipper_transport_number ?? '-',
    from_to: fromTo(tour),
    start_time: tour.start_time !== null ? formatDateWithTimeZone(tour.start_time, timeZone) : '-',
    iso_start_time: tour.start_time ?? '-',
    tags: tour.tags,
    shipper_name: tour.shipper?.company_name ?? '-',
    shipper_id: tour.shipper?.company_id ?? null,
    carrier_name: tour.carrier?.company_name ?? '-',
    carrier_id: tour.carrier?.company_id ?? null,
  }));

  return (
    <div className="tour__tour-list">
      <TourTable
        className="ui very basic sortable unstackable table"
        data={formattedTours}
        sortBy={sortBy}
        onSortByChange={onSortChange}
        tableHeaders={{
          defaultClassName: getTableHeaderClassNames,
          columns: [
            { value: '#' },
            { value: 'From - To' },
            { value: 'Status' },
            { value: 'Issues' },
            { value: 'Shipper', keyPath: 'shipper_name' },
            { value: 'Carrier', keyPath: 'carrier_name' },
            { value: 'Vehicle(s)' },
            { value: 'Shipper Transport No' },
            { value: 'Transport ID' },
            { value: 'Platform' },
            { value: 'Start time', keyPath: 'start_time' },
            { value: 'Tags' },
            { value: 'Links' },
          ],
        }}
        emptyStatePlaceholder={emptyTableState('No tours with the selected filters')}>
        {({ row }) => {
          const { data: tour } = row;
          const mode = tour.visibility_services.includes(VisibilityService.VISIBILITY_OCEAN) ? 'ocean' : 'road';
          return (
            <tr key={tour.tour_id}>
              <td className="right aligned wide">
                <Link to={Routes.Tour.generatePath({ tour_id: tour.tour_id.toString() })}>{tour.tour_id}</Link>
              </td>
              <td>
                <Link to={Routes.Tour.generatePath({ tour_id: tour.tour_id.toString() })}>{tour.from_to}</Link>
              </td>
              <td>
                {' '}
                <div
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
                  className={classnames('ui label tiny', getStatusLabelColor(tour.status))}>
                  {tour.status ?? '-'}
                </div>
              </td>
              <td>
                <div style={{ display: 'flex', columnGap: 1, justifyContent: 'space-evenly' }}>
                  {tour.mainVisibilityIssue !== null && tour.failureReasonType === null && (
                    <Popup trigger={<Icon className="yellow eye large" />}>
                      <span style={{ fontWeight: 'bold' }}>Main visibility issue: </span>
                      <span>{tour.mainVisibilityIssue}</span>
                    </Popup>
                  )}
                  {tour.warnings !== null && (
                    <Popup trigger={<Icon className="warning large orange" />}>
                      <span style={{ fontWeight: 'bold' }}>Warnings: </span>
                      <span>{tour.warnings}</span>
                    </Popup>
                  )}
                  {tour.failureReasonType !== null && (
                    <Popup trigger={<Icon className="red large dont" />}>
                      <span style={{ fontWeight: 'bold' }}>Failure reason: </span>
                      <span>{tour.failureReasonType}</span>
                    </Popup>
                  )}
                </div>
              </td>
              <td>
                {tour.shipper_id !== null ? (
                  <Link to={Routes.Company.generatePath({ company_id: tour.shipper_id })}>{tour.shipper_name}</Link>
                ) : (
                  '-'
                )}
              </td>
              <td>
                {tour.carrier_id !== null ? (
                  <Link to={Routes.Company.generatePath({ company_id: tour.carrier_id })}>{tour.carrier_name}</Link>
                ) : (
                  '-'
                )}
              </td>
              <td>
                {tour.vehicles.map(({ vehicle_id, license_plate_number }) => (
                  <React.Fragment key={vehicle_id}>
                    {license_plate_number !== null ? (
                      <Link to={Routes.Vehicle.generatePath({ vehicle_id })}>{license_plate_number}</Link>
                    ) : (
                      <i>ID#{vehicle_id}</i>
                    )}
                    &nbsp;
                  </React.Fragment>
                ))}
              </td>
              <td>{tour.shipper_transport_number}</td>
              <td>{tour.transport_id ?? '-'}</td>
              <td>{tour.platform_name}</td>
              <td>{tour.start_time}</td>
              <td>
                <Tags tags={tour.tags} />
              </td>
              <td>
                {tour.transport_id !== null ? (
                  <React.Fragment>
                    {tour.shipper_id !== null ? (
                      <a
                        target="_blank"
                        title="Shipper"
                        href={shipmentViewUrl(tour.shipper_id, tour.tour_id.toString(), mode)}
                        rel="noreferrer">
                        📦
                      </a>
                    ) : (
                      <span>No shipper ID to link to</span>
                    )}
                    &nbsp;
                    {tour.carrier_id !== null ? (
                      <a
                        target="_blank"
                        title="Carrier"
                        href={shipmentViewUrl(tour.carrier_id, tour.tour_id.toString(), mode)}
                        rel="noreferrer">
                        🚛
                      </a>
                    ) : (
                      <span>No carrier ID to link to</span>
                    )}
                  </React.Fragment>
                ) : (
                  'No transport ID to link to'
                )}
              </td>
            </tr>
          );
        }}
      </TourTable>
    </div>
  );
};
