import { Loader } from '@sixfold/loader-container';
import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { CompanyRelationshipMutations } from './relationship_mutations_container';
import { InfiniteScroll } from '../../components/infinitescroll';
import {
  CompanyRelationshipsListQuery as CompanyRelationshipsListQueryResult,
  CompanyRelationshipsListQueryVariables,
  getNodes,
  PAGINATION_PAGE_SIZE,
  loadMoreFromConnection,
} from '../../lib/graphql';
import { RelationshipList, RelationshipListDataProps } from '../components/relationship_list';
import { companyRelationshipsListQuery } from '../graphql';

class LoadingContainer extends Loader<
  CompanyRelationshipsListQueryResult,
  RelationshipListDataProps,
  CompanyRelationshipsListQueryVariables
> {}
class CompanyRelationshipsListQuery extends Query<
  CompanyRelationshipsListQueryResult,
  CompanyRelationshipsListQueryVariables
> {}

export const RelationshipsContainer = (props: RouteComponentProps<{ company_id: string }>) => (
  <React.Fragment>
    <CompanyRelationshipMutations>
      {({
        deleteCompanyRelationship,
        updateCompanyRelationshipOnboardingStatus,
        updateCompanyRelationshipSignupStatus,
      }) => (
        <CompanyRelationshipsListQuery
          fetchPolicy="cache-and-network"
          query={companyRelationshipsListQuery}
          variables={{ companyId: props.match.params.company_id, limit: PAGINATION_PAGE_SIZE }}>
          {(result) => (
            <LoadingContainer
              result={result}
              options={{
                showLoadingPlaceholder: ({ networkStatus, loading }) => {
                  const isFetchingMore = networkStatus === 3;
                  return isFetchingMore ? false : loading;
                },
              }}
              mapData={({ data }) => ({
                data: {
                  companyRelationships: getNodes(data.companyRelationships),
                  company_id: props.match.params.company_id,
                },
              })}>
              {({ data }) => (
                <InfiniteScroll
                  triggerOnWindow={false}
                  loadMoreEntries={() =>
                    loadMoreFromConnection(companyRelationshipsListQuery, result, ['companyRelationships'])
                  }>
                  <RelationshipList
                    data={data}
                    deleteCompanyRelationship={deleteCompanyRelationship}
                    updateCompanyRelationshipOnboardingStatus={updateCompanyRelationshipOnboardingStatus}
                    updateCompanyRelationshipSignupStatus={updateCompanyRelationshipSignupStatus}
                  />
                </InfiniteScroll>
              )}
            </LoadingContainer>
          )}
        </CompanyRelationshipsListQuery>
      )}
    </CompanyRelationshipMutations>
  </React.Fragment>
);
