import { notNil, isNil } from '@sixfold/typed-primitives';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  GridRow,
  GridColumn,
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Button,
  Dropdown,
} from 'semantic-ui-react';

import { OnboardingViewProps } from './company_onboarding';
import { SignupVisibilitySources } from './signup_visibility_sources';
import { FormattedDate } from '../../../components/date_formatting/formatted_date';
import { UnifiedCompanySignupEventType, UnifiedCompanySignupStatusUpdateType } from '../../../lib/graphql';
import { beautifyString } from '../../../lib/util/string';
import { Routes } from '../../../routes';

export const SignupStatus: React.FC<OnboardingViewProps> = (props) => {
  const signup = {
    status: props.data.company?.unifiedSignupStatus ?? null,
    events: props.data.company?.unifiedSignupEvents ?? [],
    signupVisibilitySources: props.data.company?.signupVisibilitySources ?? [],
    signupVisibilitySourcesViaSubcarriers: props.data.company?.signupVisibilitySourcesViaSubcarriers ?? [],
  };

  const [isLoading, setIsLoading] = React.useState(false);

  const updateSignupStatus = React.useCallback(
    async (update: UnifiedCompanySignupStatusUpdateType) => {
      const companyId = props.data.company?.company_id;

      if (isNil(companyId)) {
        throw new Error('No company to update');
      }

      setIsLoading(true);

      try {
        await props.updateSignupStatus(companyId, { update });
      } finally {
        setIsLoading(false);
      }
    },
    [props],
  );

  return (
    <Grid>
      <GridRow>
        <GridColumn width="12" verticalAlign="middle">
          Onboarding status: <b>{notNil(signup.status) ? beautifyString(signup.status) : 'N/A'}</b>
        </GridColumn>
        <GridColumn width="4" textAlign="right">
          <Dropdown
            disabled={isLoading}
            icon={null}
            pointing="top right"
            trigger={
              <Button size="mini" loading={isLoading}>
                Update signup status
              </Button>
            }>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => updateSignupStatus(UnifiedCompanySignupStatusUpdateType.RESET_TO_AUTOMATIC)}>
                Reset to calculated status
              </Dropdown.Item>
              <Dropdown.Item onClick={() => updateSignupStatus(UnifiedCompanySignupStatusUpdateType.ONBOARDED)}>
                Manually onboarded
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </GridColumn>
      </GridRow>

      <GridRow>
        <GridColumn>
          Visibility sources:{' '}
          <SignupVisibilitySources visibilitySources={signup.signupVisibilitySources} events={signup.events} />
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn>
          Visibility sources via subcarriers:{' '}
          <SignupVisibilitySources visibilitySources={signup.signupVisibilitySourcesViaSubcarriers} events={null} />
        </GridColumn>
      </GridRow>

      <GridRow>
        <GridColumn width="16">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell width="4">Time</TableHeaderCell>
                <TableHeaderCell width="8">Event</TableHeaderCell>
                <TableHeaderCell width="4">Triggered by</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {signup.events.length === 0 ? (
                <TableRow>
                  <TableCell textAlign="center" colSpan="3">
                    No events
                  </TableCell>
                </TableRow>
              ) : (
                signup.events.map(({ eventTime, type, triggeredByUser }, idx) => (
                  <TableRow
                    key={idx}
                    warning={type === UnifiedCompanySignupEventType.MANUALLY_ONBOARDED}
                    positive={type === UnifiedCompanySignupEventType.ONBOARDED}>
                    <TableCell>
                      <FormattedDate date={eventTime} />
                    </TableCell>
                    <TableCell>
                      <span>{beautifyString(type)}</span>
                    </TableCell>
                    <TableCell>
                      {triggeredByUser?.user_id !== undefined ? (
                        <Link to={Routes.User.generatePath({ user_id: triggeredByUser.user_id })}>
                          {triggeredByUser.first_name} {triggeredByUser.last_name}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
