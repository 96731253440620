import { gql } from 'graphql-tag';

import { integrationInfoFragment } from '../telematic_integration/graphql';

const companyMetadataFragment = gql`
  fragment CompanyMetadata on Company {
    company_name
    feature_flags
    is_shipper
    is_carrier
    is_pilot
    is_paying_customer
    is_test

    fms_integrated_at
    isPendingSignup
    signupCompletedAt
    vatNumbers {
      vatNumber
    }

    fieldDataSources {
      companyName
      isShipper
      isCarrier
      isTest
    }

    companyIdentifiers {
      companyIdentifierId
      discriminatorCountry
      identifierType
      identifierValue
      source
      companyId
    }

    tags {
      name
    }
  }
`;

export const companiesQuery = gql`
  ${companyMetadataFragment}

  query CompanyList(
    $limit: Int
    $cursor: String
    $type: CompanyTypeInput
    $textSearch: String
    $tagName: String
    $onboardingStatus: UnifiedCompanyOnboardingStatus
    $onboardingStuckReason: UnifiedCompanyOnboardingStuckReason
  ) {
    availableCompanyTags {
      name
    }
    companies(
      first: $limit
      after: $cursor
      type: $type
      textSearch: $textSearch
      tagName: $tagName
      onboardingStatus: $onboardingStatus
      onboardingStuckReason: $onboardingStuckReason
    ) {
      edges {
        node {
          company_id
          ...CompanyMetadata

          shipperToursCount
          carrierToursCount
          totalVehiclesCount

          unifiedOnboardingStatus
          unifiedOnboardingStuckReason

          platforms {
            platform_id
            platform_name
            company_id_on_platform
          }

          companyIdentifiers {
            companyIdentifierId
            identifierType
            identifierValue
          }
        }
      }

      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const companyQuery = gql`
  ${companyMetadataFragment}

  query CompanyDetails($company_id: String!) {
    availableCompanyTags {
      name
    }

    company(company_id: $company_id) {
      company_id
      ...CompanyMetadata
    }
  }
`;

export const companyFeatureFlagsMetadataQuery = gql`
  query CompanyWithFeatureFlagMetadata($company_id: String!, $applies_to: String!, $first: Int, $cursor: String) {
    company(company_id: $company_id) {
      company_id
      company_name
      feature_flags
      featureFlagAuditLogConnection(first: $first, after: $cursor) {
        edges {
          node {
            featureFlagAuditLogId
            featureFlagId
            user {
              user_id
              first_name
              last_name
            }
            createdAt
            metadata {
              ... on FlagCreatedMetadata {
                action
                value
              }
              ... on FlagUpdatedMetadata {
                action
                oldValue
                newValue
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }

    featureFlagMetadata(applies_to: $applies_to) {
      feature_flag_id
      display_name
      description
      usage
      domains
    }
  }
`;

export const updateCompanyMutation = gql`
  ${companyMetadataFragment}

  mutation UpdateCompany($company_id: String!, $input: UpdateCompanyInput) {
    updateCompany(company_id: $company_id, input: $input) {
      company_id
      ...CompanyMetadata

      assets {
        consigneeLogo {
          url
        }

        consigneeBackground {
          url
        }
      }
    }
  }
`;

export const createCompanyIdentifierMutation = gql`
  mutation CreateCompanyIdentifier($input: CreateCompanyIdentifierInput!) {
    createCompanyIdentifier(input: $input) {
      success
    }
  }
`;

export const deleteCompanyIdentifierMutation = gql`
  mutation DeleteCompanyIdentifier($input: DeleteCompanyIdentifierInput!) {
    deleteCompanyIdentifier(input: $input) {
      success
    }
  }
`;

export const createVehiclesMutation = gql`
  mutation CreateVehicles($input: CreateVehiclesInput!) {
    createVehicles(input: $input)
  }
`;

export const createCompanyMutation = gql`
  ${companyMetadataFragment}

  mutation CreateCompany($input: CreateCompanyInput) {
    createCompany(input: $input) {
      company_id
      ...CompanyMetadata

      shipperToursCount
      carrierToursCount
      totalVehiclesCount

      unifiedOnboardingStatus
      unifiedOnboardingStuckReason

      platforms {
        platform_id
        platform_name
        company_id_on_platform
      }
    }
  }
`;

export const companyShipperToursByCarrierMetricsQuery = gql`
  query CompanyShipperToursByCarriersMetrics($company_id: String!, $first: Int, $cursor: String) {
    company(company_id: $company_id) {
      company_id

      tourReportsByCarrier(first: $first, after: $cursor) {
        edges {
          node {
            carrier {
              company_id
              company_name
            }

            toursCount
            visibilityIndex
            allocatedToursCount
          }
        }

        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const companyCarrierToursByShipperMetricsQuery = gql`
  query CompanyCarrierToursByShippersMetrics($company_id: String!, $first: Int, $cursor: String) {
    company(company_id: $company_id) {
      company_id

      tourReportsByShipper(first: $first, after: $cursor) {
        edges {
          node {
            shipper {
              company_id
              company_name
            }

            toursCount
            visibilityIndex
            allocatedToursCount
          }
        }

        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const companyIntegrationsListQuery = gql`
  ${integrationInfoFragment}

  query CompanyIntegrationsList($company_id: String!) {
    company(company_id: $company_id) {
      company_id
      telematics_integrations {
        ...IntegrationInfo
      }
    }
  }
`;

export const companyAssetsQuery = gql`
  query CompanyAssets($company_id: String!) {
    company(company_id: $company_id) {
      company_id
      assets {
        consigneeLogo {
          url
        }

        consigneeBackground {
          url
        }
      }
    }
  }
`;

export const companyOnboardingQuery = gql`
  query CompanyOnboarding($company_id: String!) {
    company(company_id: $company_id) {
      company_id
      unifiedOnboardingStatus
      unifiedOnboardingStuckReason
      unifiedOnboardingEvents {
        type
        eventTime
        stuckReason
        visibilitySource
        triggeredByUser {
          user_id
          first_name
          last_name
        }
      }

      unifiedSignupStatus
      unifiedSignupEvents {
        type
        eventTime
        visibilitySource
        triggeredByUser {
          user_id
          first_name
          last_name
        }
      }

      visibilitySources {
        status
        type
      }

      visibilitySourcesViaSubcarriers {
        status
        type
      }

      signupVisibilitySources {
        status
        type
      }

      signupVisibilitySourcesViaSubcarriers {
        status
        type
      }
    }
  }
`;

export const companyInvitationsQuery = gql`
  query CompanyInvitations($company_id: String!) {
    companyInvitations(company_id: $company_id) {
      companyInviteId
      invitedAt
      companyName
      status
      inviteMethod
      invitedByCompany {
        company_id
        company_name
      }
      invitedByUser {
        first_name
        last_name
      }
      recipients {
        email
        locale
      }
    }
  }
`;

export const updateCompanyOnboardingStatusMutation = gql`
  mutation UpdateCompanyOnboardingStatus($company_id: String!, $input: UnifiedCompanyOnboardingStatusUpdateInput!) {
    updateUnifiedCompanyOnboardingStatus(companyId: $company_id, input: $input)
  }
`;

export const updateCompanySignupStatusMutation = gql`
  mutation UpdateCompanySignupStatus($company_id: String!, $input: UnifiedCompanySignupStatusUpdateInput!) {
    updateUnifiedCompanySignupStatus(companyId: $company_id, input: $input)
  }
`;

export const companyDropdownQuery = gql`
  ${companyMetadataFragment}

  query CompanyDropdown($companyId: String!) {
    company(company_id: $companyId) {
      company_id
      ...CompanyMetadata
    }
  }
`;

export const companiesDropdownListQuery = gql`
  ${companyMetadataFragment}

  query CompaniesDropdownList($textSearch: String, $type: CompanyTypeInput) {
    companies(first: 100, textSearch: $textSearch, type: $type) {
      edges {
        node {
          company_id
          ...CompanyMetadata
        }
      }
    }
  }
`;

export const companyConfigurationQuery = gql`
  query CompanyConfiguration($company_id: String!) {
    company(company_id: $company_id) {
      company_id
      configuration {
        configurations {
          customerPortal {
            primaryTransportIdentifier
            detailViewTransportIdentifiers
          }
          sharedTourView {
            visibleTransportIdentifiers
          }
          sharedStopView {
            visibleTransportIdentifiers
          }
        }
      }
    }
  }
`;

export const updateCompanyConfigurationMutation = gql`
  mutation UpdateCompanyConfiguration($input: UpdateCompanyConfigurationInput!) {
    updateCompanyConfiguration(input: $input) {
      company_id
      configuration {
        configurations {
          customerPortal {
            primaryTransportIdentifier
            detailViewTransportIdentifiers
          }
          sharedTourView {
            visibleTransportIdentifiers
          }
          sharedStopView {
            visibleTransportIdentifiers
          }
        }
      }
    }
  }
`;

export const telematicsIntegrationSensitiveConnectionDataQuery = gql`
  query SensitiveConnectionData($telematics_integration_id: String!, $captcha_token: String!) {
    sensitiveConnectionData(telematics_integration_id: $telematics_integration_id, captcha_token: $captcha_token)
  }
`;

export const telematicsIntegrationSensitiveDataQuery = gql`
  query SensitiveData($telematics_integration_id: String!, $captcha_token: String!) {
    sensitiveData(telematics_integration_id: $telematics_integration_id, captcha_token: $captcha_token) {
      connectionData
      syncStateData
    }
  }
`;

export const companyDashboardsQuery = gql`
  query CompanyDashboards($company_id: String!) {
    company(company_id: $company_id) {
      company_id
      lightdashEmbeddings {
        allocationSourceAndSuccessUrl
      }
    }
  }
`;
