import { IconLayer, ScatterplotLayer, PathLayer } from 'deck.gl';

import { Vehicle, VehicleHistory, VehicleWithLocation, VehicleStatus } from '../entities';
import { vehiclesWithLocation } from '../utils';

interface VehicleProps {
  vehicles: (Vehicle & { status: VehicleStatus | null })[];

  onClick?(vehicle: VehicleWithLocation): void;
}

interface HistoryProps {
  vehicles: (Vehicle & { history?: VehicleHistory[] })[];

  onClick?({ lat, lng }: { lat: number | null; lng: number | null }): void;
}

const IconMapping = {
  ghost: { x: 0, y: 0, width: 64, height: 64, mask: true },
  truck: { x: 0, y: 0, width: 64, height: 64 },
};

export const GhostVehicleColors = [
  [246, 195, 33],
  [0, 118, 255],
  [111, 18, 13],
  [166, 50, 62],
  [235, 53, 47],
  [221, 178, 239],
  [168, 133, 93],
  [218, 1, 253],
  [36, 9, 185],
  [62, 145, 119],
];

const darkenColor = ([r, g, b]: [r: number, g: number, b: number], darkThreshold: number): [number, number, number] => {
  return [
    Math.max(0, Math.floor(r * (1 - darkThreshold))),
    Math.max(0, Math.floor(g * (1 - darkThreshold))),
    Math.max(0, Math.floor(b * (1 - darkThreshold))),
  ];
};

const darkenGhostVehicleColors = GhostVehicleColors.map(([r, g, b]) => darkenColor([r, g, b], 0.4));

export const createVehiclesLayer = ({ vehicles, onClick }: VehicleProps) => {
  return new IconLayer({
    id: 'vehiclesLayer',
    data: vehiclesWithLocation(vehicles),
    iconAtlas: '/assets/vehicle_position.png',
    iconMapping: IconMapping,
    pickable: true,
    onClick: ({ object }) => onClick !== undefined && onClick(object),
    getPosition: ({ status }) => [status.longitude, status.latitude, 0],
    getAngle: ({ status }) => status.heading * -1,
    getSize: () => 38,
    getIcon: () => 'truck',
  });
};

export const createGhostVehicleLayer = (vehicleHistory: VehicleHistory, index: number) => {
  return new IconLayer({
    id: `ghostVehicleLayer${index}`,
    data: [vehicleHistory],
    iconAtlas: '/assets/ghost_position.png',
    iconMapping: IconMapping,
    pickable: false,
    getPosition: (object) => [object.lng, object.lat, 0],
    getAngle: (object) => (object.hdg !== null ? object.hdg * -1 : 0),
    getSize: () => 32,
    getIcon: () => 'ghost',
    getColor: () => (index < darkenGhostVehicleColors.length ? darkenGhostVehicleColors[index] : [0, 0, 128]),
  });
};

const getColorFromIdx = (idx: number, vehicleType: string | null) => {
  // In case a single mode is involved use the predefined colors list
  const modeq = vehicleType === null || vehicleType === 'TRACTOR' ? 0 : 1;
  if (modeq === 0) {
    return idx < GhostVehicleColors.length ? GhostVehicleColors[idx] : [0, 0, 128];
  }

  return [modeq * 155 + idx * 25, (300 * (2 - modeq)) / (idx + 6), idx * 25, 70];
};

export const createVehiclesHistoryLayer = ({ vehicles, onClick }: HistoryProps) => {
  const vehiclesWithHistory = vehicles.filter((vehicle) => vehicle.history) as (Vehicle & {
    history: VehicleHistory[];
  })[];

  const vehiclesByMode = vehiclesWithHistory.flatMap((vehicle) =>
    vehicle.history.reduce(
      (memo, position) => {
        const previous = memo[memo.length - 1];
        if (
          previous === undefined ||
          previous.history[previous.history.length - 1].vehicleType !== position.vehicleType
        ) {
          return [...memo, { ...vehicle, history: [position] }];
        }
        return [...memo.slice(0, -1), { ...previous, history: [...previous.history, position] }];
      },
      [] as typeof vehiclesWithHistory,
    ),
  );

  const historyZones = vehiclesByMode.reduce(
    (previous, vehicle, idx) => {
      return [
        ...previous,
        ...vehicle.history.map((p) => ({
          position: [p.lng, p.lat],
          radius: 100,
          radiusScale: 3,
          radiusMinPixels: 75,
          radiusMaxPixels: 150,
          color: getColorFromIdx(idx, p.vehicleType),
        })),
      ];
    },
    [] as {
      position: number[];
      radius: number;
      radiusScale: number;
      radiusMinPixels: number;
      radiusMaxPixels: number;
      color: number[];
    }[],
  );

  const historyPathsByMode = vehiclesWithHistory.flatMap((vehicle) => {
    return vehicle.history.reduce<{ path: [number, number][]; vehicleType: string | null }[]>((acc, pos) => {
      const coords = [pos.lng, pos.lat] as [number, number];
      acc[acc.length - 1]?.path.push(coords);
      if (acc.length === 0 || pos.vehicleType !== acc[acc.length - 1].vehicleType) {
        acc.push({ path: [coords], vehicleType: pos.vehicleType });
      }
      return acc;
    }, []);
  });

  const historyPaths = historyPathsByMode.map(({ path, vehicleType }, idx) => ({
    path,
    color: getColorFromIdx(idx, vehicleType),
  }));

  const pointLayer = new ScatterplotLayer({
    id: 'historyLayer',
    pickable: true,
    onClick:
      onClick !== undefined ? ({ object }) => onClick({ lat: object.position[1], lng: object.position[0] }) : undefined,
    data: historyZones,
    getRadius: (l) => l.radius,
    getFillColor: (l) => l.color,
  });

  const pathLayer = new PathLayer({
    id: 'historyRouteLayer',
    data: historyPaths,
    rounded: false,
    widthMinPixels: 1,
    widthScale: 5,
    getColor: (l) => l.color,
  });

  return { pointLayer, pathLayer };
};
