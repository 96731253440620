import { isNil } from '@sixfold/typed-primitives';

import {
  VehicleWithLocation,
  Vehicle,
  VehicleBreakHistory,
  VehicleHistory,
  BreakType,
  VehicleStatus,
  VehicleTracker,
  MobileVehicleTracker,
  FMSVehicleTracker,
} from './entities';
import { differenceInHours, formattedDuration } from '../lib/date';
import { VehicleHistoryQuery, TourVehicleHistoryQuery, VehicleTrackerType } from '../lib/graphql';
import { Tour } from '../tour/entities';

export const isMobileTracker = (tracker: Pick<VehicleTracker, 'trackerType'>): tracker is MobileVehicleTracker =>
  tracker.trackerType === VehicleTrackerType.MOBILE_APP;

export const isFMSTracker = (tracker: VehicleTracker | undefined | null): tracker is FMSVehicleTracker =>
  tracker?.trackerType === VehicleTrackerType.FMS_DEVICE;

export const vehiclesWithLocation = (vehicles: (Vehicle & { status: VehicleStatus | null })[]) =>
  vehicles.reduce<VehicleWithLocation[]>((memo, vehicle) => {
    return [
      ...(vehicle.status !== null && vehicle.status.latitude !== null && vehicle.status.longitude !== null
        ? [
            {
              ...vehicle,
              status: {
                latitude: vehicle.status.latitude,
                longitude: vehicle.status.longitude,
                heading: vehicle.status.heading !== null ? vehicle.status.heading : 0,
                timestamp: vehicle.status.timestamp,
              },
            },
          ]
        : []),
      ...memo,
    ];
  }, []);

export const mergeTourVehiclesWithVehiclesHistory = (
  historyData: TourVehicleHistoryQuery | undefined,
  tourVehicles: Tour['vehicles'],
  historyEndTime: Date,
): (Vehicle & { history: VehicleHistory[]; breakHistory: VehicleBreakHistory[]; status: VehicleStatus | null })[] => {
  const tour = historyData?.tour ?? undefined;
  const vehicleHistories = tour?.vehicleHistoryList ?? [];
  const breakHistories = tour?.vehicleBreakHistory ?? [];
  const statuses = tour?.vehicleStatuses ?? [];

  const uniqueVehicleIds = Array.from(
    new Set([
      ...vehicleHistories.map(({ vehicle_id }) => vehicle_id),
      ...breakHistories.map(({ vehicle_id }) => vehicle_id),
      ...statuses.map(({ vehicle_id }) => vehicle_id),
    ]),
  );

  return uniqueVehicleIds.map((vehicleId) => {
    const tourVehicle = tourVehicles.find(({ vehicle_id }) => vehicle_id === vehicleId);
    const history = vehicleHistories.find(({ vehicle_id }) => vehicle_id === vehicleId);
    const breakHistory = breakHistories.find(({ vehicle_id }) => vehicle_id === vehicleId);
    const vehicleStatus = statuses.find(({ vehicle_id }) => vehicle_id === vehicleId);

    return {
      history: history !== undefined ? history.history : [],
      breakHistory: breakHistory !== undefined ? processVehicleBreakHistory(breakHistory.breaks, historyEndTime) : [],
      status: vehicleStatus ?? null,
      vehicle_id: vehicleId,
      license_plate_number: tourVehicle !== undefined ? tourVehicle.license_plate_number : null,
      company: tourVehicle !== undefined ? tourVehicle.company : null,
      inGlobalPool: tourVehicle?.inGlobalPool ?? null,
    };
  });
};

export const processVehicleHistoryData = (
  historyData: VehicleHistoryQuery | undefined,
  historyEndTime: Date,
): { vehicleHistory: VehicleHistory[]; breakHistory: VehicleBreakHistory[] } => {
  const vehicle = historyData?.vehicle;

  if (isNil(vehicle)) {
    return { vehicleHistory: [], breakHistory: [] };
  }

  // Apollo cache might already have the vehicle due to another query but no break nor vehicle history yet
  const history = vehicle.history ?? [];
  const breaks = vehicle.breakHistory ?? [];

  const vehicleHistory = history.map(({ lat, lng, hdg, timestamp, source, received_at }) => ({
    lat,
    lng,
    timestamp,
    hdg,
    source,
    received_at,
    modeChangesLeft: null,
    vehicleType: null,
  }));

  const breakHistory = processVehicleBreakHistory(breaks, historyEndTime);

  return { vehicleHistory, breakHistory };
};

function processVehicleBreakHistory(
  breakHistory: NonNullable<VehicleHistoryQuery['vehicle']>['breakHistory'],
  historyEndTime: Date,
): VehicleBreakHistory[] {
  return (
    breakHistory?.map((breakUnit) => {
      const { type, durationInHours, formattedDuration } = getBreakProperties(
        breakUnit.from,
        breakUnit.to !== null ? breakUnit.to : historyEndTime,
      );

      return { ...breakUnit, type, durationInHours, formattedDuration };
    }) ?? []
  );
}

function getBreakProperties(
  breakFrom: string | Date,
  breakTo: string | Date,
): { type: BreakType; durationInHours: number; formattedDuration: string } {
  const durationInHours = differenceInHours(breakTo, breakFrom);
  const durationString = formattedDuration(breakFrom, breakTo);

  // Similar to the constant defined for the prediction algorithm (naming as well)
  // except that we show all breaks (min 5min) as opposed to prediction (min 45min = 0.75h)
  if (durationInHours >= 24) {
    return { type: BreakType.weekendBreak, durationInHours, formattedDuration: durationString };
  }

  if (durationInHours >= 9) {
    return { type: BreakType.rest, durationInHours, formattedDuration: durationString };
  }

  return { type: BreakType.break, durationInHours, formattedDuration: durationString };
}
