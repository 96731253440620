import { useNotifications } from '@sixfold/common-ui';
import { renderChildren, Children } from '@sixfold/typed-render-props';
import { gql } from 'graphql-tag';
import React from 'react';
import { Mutation } from 'react-apollo';

import {
  CreateCompanyRelationshipMutation as CreateCompanyRelationshipMutationResult,
  CreateCompanyRelationshipMutationVariables,
  DeleteCompanyRelationshipMutation as DeleteCompanyRelationshipMutationResult,
  DeleteCompanyRelationshipMutationVariables,
  UpdateCompanyRelationshipOnboardingStatusMutation as UpdateCompanyRelationshipOnboardingStatusMutationResult,
  UpdateCompanyRelationshipOnboardingStatusMutationVariables,
  PAGINATION_PAGE_SIZE,
  UpdateCompanyRelationshipSignupStatusMutation as UpdateCompanyRelationshipSignupStatusMutationResult,
  UpdateCompanyRelationshipSignupStatusMutationVariables,
} from '../../lib/graphql';
import { companyRelationshipsListQuery } from '../graphql';

const createCompanyRelationshipMutation = gql`
  mutation CreateCompanyRelationship($input: CreateCompanyRelationshipInput!) {
    createCompanyRelationship(input: $input) {
      companyRelationshipId
      sourceCompany {
        company_id
        company_name
      }
      targetCompany {
        company_id
        company_name
      }
      relationshipType
    }
  }
`;

const deleteCompanyRelationshipMutation = gql`
  mutation DeleteCompanyRelationship($input: DeleteCompanyRelationshipInput!) {
    deleteCompanyRelationship(input: $input)
  }
`;

const updateCompanyRelationshipOnboardingStatusMutation = gql`
  mutation UpdateCompanyRelationshipOnboardingStatus($input: UpdateCompanyRelationshipOnboardingStatusInput!) {
    updateCompanyRelationshipOnboardingStatus(input: $input)
  }
`;

const updateCompanyRelationshipSignupStatusMutation = gql`
  mutation UpdateCompanyRelationshipSignupStatus($input: UpdateCompanyRelationshipSignupStatusInput!) {
    updateCompanyRelationshipSignupStatus(input: $input)
  }
`;

interface Props {
  children: Children<{
    createCompanyRelationship: (input: CreateCompanyRelationshipMutationVariables['input']) => Promise<void>;
    deleteCompanyRelationship: (companyRelationshipId: string, selectedCompanyId: string) => Promise<void>;
    updateCompanyRelationshipOnboardingStatus: (
      input: UpdateCompanyRelationshipOnboardingStatusMutationVariables['input'],
      selectedCompanyId: string,
    ) => Promise<void>;
    updateCompanyRelationshipSignupStatus: (
      input: UpdateCompanyRelationshipSignupStatusMutationVariables['input'],
      selectedCompanyId: string,
    ) => Promise<void>;
  }>;
}

class DeleteCompanyRelationshipMutation extends Mutation<
  DeleteCompanyRelationshipMutationResult,
  DeleteCompanyRelationshipMutationVariables
> {}

class CreateCompanyRelationshipMutation extends Mutation<
  CreateCompanyRelationshipMutationResult,
  CreateCompanyRelationshipMutationVariables
> {}

class UpdateCompanyRelationshipOnboardingStatusMutation extends Mutation<
  UpdateCompanyRelationshipOnboardingStatusMutationResult,
  UpdateCompanyRelationshipOnboardingStatusMutationVariables
> {}

class UpdateCompanyRelationshipSignupStatusMutation extends Mutation<
  UpdateCompanyRelationshipSignupStatusMutationResult,
  UpdateCompanyRelationshipSignupStatusMutationVariables
> {}

export const CompanyRelationshipMutations: React.FunctionComponent<Props> = (props) => {
  const notify = useNotifications();

  return (
    <CreateCompanyRelationshipMutation mutation={createCompanyRelationshipMutation}>
      {(createCompanyRelationship) => (
        <DeleteCompanyRelationshipMutation mutation={deleteCompanyRelationshipMutation}>
          {(deleteCompanyRelationship) => (
            <UpdateCompanyRelationshipSignupStatusMutation mutation={updateCompanyRelationshipSignupStatusMutation}>
              {(updateCompanyRelationshipSignupStatus) => (
                <UpdateCompanyRelationshipOnboardingStatusMutation
                  mutation={updateCompanyRelationshipOnboardingStatusMutation}>
                  {(updateCompanyRelationshipOnboardingStatus) => {
                    return renderChildren(props.children, {
                      createCompanyRelationship: async (input: CreateCompanyRelationshipMutationVariables['input']) => {
                        const response = await createCompanyRelationship({
                          variables: {
                            input,
                          },
                        });
                        notify.success({
                          title: 'Relationship created',
                        });

                        return response;
                      },
                      deleteCompanyRelationship: async (companyRelationshipId: string, selectedCompanyId: string) => {
                        const response = await deleteCompanyRelationship({
                          variables: {
                            input: {
                              companyRelationshipId,
                            },
                          },
                          refetchQueries: [
                            {
                              query: companyRelationshipsListQuery,
                              variables: {
                                companyId: selectedCompanyId,
                                limit: PAGINATION_PAGE_SIZE,
                              },
                            },
                          ],
                        });
                        notify.success({ title: 'Relationship deleted' });
                        return response;
                      },
                      updateCompanyRelationshipOnboardingStatus: async (
                        input: UpdateCompanyRelationshipOnboardingStatusMutationVariables['input'],
                        selectedCompanyId: string,
                      ) => {
                        const response = await updateCompanyRelationshipOnboardingStatus({
                          awaitRefetchQueries: true,
                          variables: { input },
                          refetchQueries: [
                            {
                              query: companyRelationshipsListQuery,
                              variables: {
                                companyId: selectedCompanyId,
                                limit: PAGINATION_PAGE_SIZE,
                              },
                            },
                          ],
                        });
                        notify.success({ title: 'Company relationship onboarding status updated' });
                        return response;
                      },
                      updateCompanyRelationshipSignupStatus: async (
                        input: UpdateCompanyRelationshipSignupStatusMutationVariables['input'],
                        selectedCompanyId: string,
                      ) => {
                        const response = await updateCompanyRelationshipSignupStatus({
                          awaitRefetchQueries: true,
                          variables: { input },
                          refetchQueries: [
                            {
                              query: companyRelationshipsListQuery,
                              variables: {
                                companyId: selectedCompanyId,
                                limit: PAGINATION_PAGE_SIZE,
                              },
                            },
                          ],
                        });
                        notify.success({ title: 'Company relationship signup status updated' });
                        return response;
                      },
                    });
                  }}
                </UpdateCompanyRelationshipOnboardingStatusMutation>
              )}
            </UpdateCompanyRelationshipSignupStatusMutation>
          )}
        </DeleteCompanyRelationshipMutation>
      )}
    </CreateCompanyRelationshipMutation>
  );
};
