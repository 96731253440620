import { Loader } from '@sixfold/loader-container';
import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { UpdateCompanyOnboardingStatusMutation } from './update_company_onboarding_status_container';
import { UpdateCompanySignupStatusMutation } from './update_company_signup_status_container';
import { CompanyOnboardingQuery, CompanyOnboardingQueryVariables } from '../../lib/graphql';
import { CompanyOnboardingView, OnboardingViewProps } from '../components/company_onboarding/company_onboarding';
import { companyOnboardingQuery } from '../graphql';

class LoadingContainer extends Loader<CompanyOnboardingQuery, OnboardingViewProps, CompanyOnboardingQueryVariables> {}
class CompanyContainerQuery extends Query<CompanyOnboardingQuery, CompanyOnboardingQueryVariables> {}

export const CompanyOnboardingContainer = (props: RouteComponentProps<{ company_id: string }>) => (
  <UpdateCompanyOnboardingStatusMutation>
    {({ updateCompanyOnboardingStatus }) => (
      <UpdateCompanySignupStatusMutation>
        {({ updateCompanySignupStatus }) => (
          <CompanyContainerQuery
            query={companyOnboardingQuery}
            variables={{
              company_id: props.match.params.company_id,
            }}>
            {(result) => (
              <LoadingContainer
                result={result}
                mapData={({ data }) => ({
                  data: { company: data.company ?? null, __typename: 'Query' },
                  updateOnboardingStatus: updateCompanyOnboardingStatus,
                  updateSignupStatus: updateCompanySignupStatus,
                })}>
                {(props) => <CompanyOnboardingView {...props} />}
              </LoadingContainer>
            )}
          </CompanyContainerQuery>
        )}
      </UpdateCompanySignupStatusMutation>
    )}
  </UpdateCompanyOnboardingStatusMutation>
);
