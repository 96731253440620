import { useNotifications } from '@sixfold/common-ui';
import { renderChildren, Children } from '@sixfold/typed-render-props';
import React from 'react';
import { Mutation } from 'react-apollo';

import {
  UpdateCompanySignupStatusMutation as MutationType,
  UpdateCompanySignupStatusMutationVariables,
  UnifiedCompanySignupStatusUpdateInput,
} from '../../lib/graphql';
import { companyOnboardingQuery, updateCompanySignupStatusMutation } from '../graphql';

class UpdateCompanySignupStatus extends Mutation<MutationType, UpdateCompanySignupStatusMutationVariables> {}

interface Props {
  children: Children<{
    updateCompanySignupStatus: (companyId: string, input: UnifiedCompanySignupStatusUpdateInput) => Promise<void>;
  }>;
}

export const UpdateCompanySignupStatusMutation: React.FunctionComponent<Props> = ({ children }) => {
  const notify = useNotifications();

  return (
    <UpdateCompanySignupStatus mutation={updateCompanySignupStatusMutation}>
      {(updateCompanySignupStatus) =>
        renderChildren(children, {
          updateCompanySignupStatus: async (companyId: string, input: UnifiedCompanySignupStatusUpdateInput) => {
            const response = await updateCompanySignupStatus({
              refetchQueries: [
                {
                  query: companyOnboardingQuery, // onboarding and signup data are currently fetched using the same query
                  variables: { company_id: companyId },
                },
              ],
              variables: {
                company_id: companyId,
                input,
              },
            });
            notify.success({ title: 'Company signup status updated' });
            return response;
          },
        })
      }
    </UpdateCompanySignupStatus>
  );
};
