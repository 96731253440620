import { EmbedConfig } from '../../../shared/types';

const isEmbedJSONAvailable = (tagName: string) => {
  {
    const dataTag = window.document.getElementById(tagName);

    if (dataTag === null) {
      return false;
    }

    try {
      const data = JSON.parse(dataTag.innerHTML);
      return typeof data === 'object' && Object.keys(data).length > 0;
    } catch (_e) {
      return false;
    }
  }
};

export const getEmbedConfig = (): EmbedConfig | null => {
  const configTag = window.document.getElementById('_CONFIG');

  if (!isEmbedJSONAvailable('_CONFIG') || configTag === null) {
    return null;
  }

  return JSON.parse(configTag.innerHTML);
};
