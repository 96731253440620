import React from 'react';
import { Button, Table } from 'semantic-ui-react';
import formatXML from 'xml-formatter';

import { FormattedDate } from '../../components/date_formatting/formatted_date';
import { RawExternalEvents, Tour } from '../entities';

const RawLogComponent: React.FunctionComponent<{
  log: string;
  headerTitle: string;
  createdAt: string;
  content?: string;
  requestPayload?: string;
  responsePayload?: string;
}> = ({ log, headerTitle, createdAt, content, requestPayload, responsePayload }) => {
  const [showsPayload, setShowsPayload] = React.useState<boolean>(false);
  return (
    <Table compact="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            {`${headerTitle} `}
            <FormattedDate date={createdAt} />
            <Button floated="right" size="mini" onClick={() => setShowsPayload(!showsPayload)}>
              {showsPayload ? 'Hide payload' : 'Show payload'}
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {showsPayload && (
          <Table.Row>
            <Table.Cell>
              <div
                style={{
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  fontFamily: 'monospace',
                  backgroundColor: '#d5e0e630',
                  padding: '8px',
                }}>
                {log}
              </div>
            </Table.Cell>
          </Table.Row>
        )}
        {showsPayload && content !== undefined && (
          <Table.Row>
            <Table.Cell>
              <div
                style={{
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  fontFamily: 'monospace',
                  backgroundColor: '#d5e0e630',
                  padding: '8px',
                }}>
                {`content:\n${content}`}
              </div>
            </Table.Cell>
          </Table.Row>
        )}
        {showsPayload && requestPayload !== undefined && (
          <Table.Row>
            <Table.Cell>
              <div
                style={{
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  fontFamily: 'monospace',
                  backgroundColor: '#d5e0e630',
                  padding: '8px',
                }}>
                {`requestPayload:\n${requestPayload}`}
              </div>
            </Table.Cell>
          </Table.Row>
        )}
        {showsPayload && responsePayload !== undefined && (
          <Table.Row>
            <Table.Cell>
              <div
                style={{
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  fontFamily: 'monospace',
                  backgroundColor: '#d5e0e630',
                  padding: '8px',
                }}>
                {`responsePayload:\n${responsePayload}`}
              </div>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export const TourExternalEventLogList: React.FunctionComponent<{
  tour: Pick<Tour, 'rawExternalEvents'>;
}> = ({ tour: { rawExternalEvents } }) => {
  const NoEvents = (
    <>
      <h3>External tracking</h3>
      <Table>
        <Table.Body>
          <Table.Row textAlign="center">
            <Table.Cell>No external tracking</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
  if (!rawExternalEvents) {
    return NoEvents;
  }

  const { trackingStates, trackingRequestsLogs, externalTrackingLogs, tourDemurrageDetentionLogs } =
    formatAndExtractValues(rawExternalEvents);

  if (trackingStates.length === 0) {
    return NoEvents;
  }

  return (
    <>
      <h3>External tracking</h3>

      <h4>External tracking status</h4>
      <Table compact="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>External id</Table.HeaderCell>
            <Table.HeaderCell>Provider</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Status reason</Table.HeaderCell>
            <Table.HeaderCell>Last update</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {trackingStates.map((st, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{st.externalId}</Table.Cell>
              <Table.Cell>{st.provider}</Table.Cell>
              <Table.Cell>{st.status}</Table.Cell>
              <Table.Cell>{st.statusReason}</Table.Cell>
              <Table.Cell>
                <FormattedDate date={st.updatedAt} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <h4>Tracking requests to external tracker</h4>
      {trackingRequestsLogs.length === 0 ? (
        <Table>
          <Table.Body>
            <Table.Row textAlign="center">
              <Table.Cell>No tracking requests</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      ) : (
        trackingRequestsLogs.map((req) => (
          <RawLogComponent
            key={`${req.createdAt}-${req.trackingRequestId}-${req.responseStatus}`}
            log={JSON.stringify(
              {
                ...req,
                requestPayload: undefined,
                responsePayload: undefined,
                __typename: undefined,
              },
              null,
              2,
            )}
            requestPayload={req.requestPayload}
            responsePayload={req.responsePayload}
            headerTitle={'External tracking request log at'}
            createdAt={req.createdAt}
          />
        ))
      )}

      <h4>Tracking input from external tracker (10 latest)</h4>
      {externalTrackingLogs.length === 0 ? (
        <Table>
          <Table.Body>
            <Table.Row textAlign="center">
              <Table.Cell>No external tracking logs</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      ) : (
        externalTrackingLogs.map((log) => (
          <RawLogComponent
            key={`${log.createdAt}-${log.contentType}`}
            log={JSON.stringify(
              {
                ...log,
                content: undefined,
                __typename: undefined,
              },
              null,
              2,
            )}
            content={log.content}
            headerTitle={`Tour external tracking log from ${log.provider} at`}
            createdAt={log.createdAt}
          />
        ))
      )}

      <h4>Demurrage and detention input from external tracker (10 latest)</h4>
      {tourDemurrageDetentionLogs.length === 0 ? (
        <Table>
          <Table.Body>
            <Table.Row textAlign="center">
              <Table.Cell>No demurrage and detention logs</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      ) : (
        tourDemurrageDetentionLogs.map((dd) => (
          <RawLogComponent
            key={`${dd.createdAt}-${dd.demurrageDetentionLogId}`}
            log={JSON.stringify(
              {
                ...dd,
                content: undefined,
                __typename: undefined,
              },
              null,
              2,
            )}
            content={dd.content}
            headerTitle={'Demurrage and detention log at'}
            createdAt={dd.createdAt}
          />
        ))
      )}
    </>
  );
};
// As some of the values received as string are in fact JSON.stringified we need to parse them so the fields won't be double stringified.
// Some fields contain raw XML payloads that require different handling
function formatAndExtractValues(rawEvents: RawExternalEvents) {
  const trackingRequestsLogs = rawEvents.tourExternalTrackingRequests.map((req) => {
    const requestPayload = parseJSONorXML(req.requestPayload);
    const responsePayload = req.responsePayload !== null ? parseJSONorXML(req.responsePayload) : undefined;
    const responseStatus = req.responseStatus ?? undefined;
    return {
      ...req,
      requestPayload,
      responsePayload,
      responseStatus,
    };
  });
  const externalTrackingLogs = rawEvents.tourExternalTrackingLogs.map((log) => {
    const content = parseJSONorXML(log.content);
    return {
      ...log,
      content,
    };
  });

  const tourDemurrageDetentionLogs = rawEvents.tourDemurrageDetentionLogs.map((dd) => {
    const content = parseJSONorXML(dd.content);
    return {
      ...dd,
      content,
    };
  });
  return {
    trackingStates: rawEvents.tourExternalTracking,
    trackingRequestsLogs,
    externalTrackingLogs,
    tourDemurrageDetentionLogs,
  };
}

function parseJSONorXML(stringToParse: string): string {
  let parsedInput;
  try {
    parsedInput = JSON.stringify(JSON.parse(stringToParse), null, 2);
  } catch (_e) {
    try {
      parsedInput = formatXML(stringToParse, { indentation: '  ' });
    } catch (_e) {
      parsedInput = 'Could not parse neither JSON or XML.';
    }
  }
  return parsedInput;
}
